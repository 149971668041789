import {
  DataPermissionScopeType,
  DataPermissionSettingDto,
  PermissionDefinitionDto,
} from "@/api/appService";

export interface PermissionGrant {
  permission: PermissionItem;
  dataLimit?: {
    scope: DataPermissionScopeType;
    details?: string[];
    permissionCode: string;
  };
}

export class PermissionItem {
  private readonly _name: string;
  private readonly _displayName: string;
  private readonly _alias?: string;
  private readonly _icon?: string;
  private readonly _isFunction: boolean;
  private readonly _enableDataPermission: boolean;
  private readonly _enableDataPermissionForCreator: boolean;
  private readonly _enableDataPermissionSpecifiedLimit: boolean;
  private readonly _dataPermissionSpecifiedDataSourceProvider?: string;

  constructor(permission: PermissionDefinitionDto) {
    const properties = (permission.properties || {}) as any;

    this._name = permission.name ?? "";
    this._displayName = permission.displayName ?? "";
    this._icon = properties.icon;
    this._isFunction = properties.function === true;
    this._alias = properties.alias;
    this._enableDataPermissionForCreator =
      properties.enableDataPermissionForCreator;
    this._enableDataPermission = properties.enableDataPermission;
    this._enableDataPermissionSpecifiedLimit =
      properties.enableDataPermissionSpecifiedLimit;
    this._dataPermissionSpecifiedDataSourceProvider =
      properties.dataPermissionSpecifiedDataSourceProvider;
  }

  get enableDataPermissionSpecifiedLimit(): boolean {
    return this._enableDataPermissionSpecifiedLimit;
  }

  get enableDataPermissionForCreator(): boolean {
    return this._enableDataPermissionForCreator;
  }

  get enableDataPermission(): boolean {
    return this._enableDataPermission;
  }

  get dataPermissionSpecifiedDataSourceProvider(): string | undefined {
    return this._dataPermissionSpecifiedDataSourceProvider;
  }

  get name(): string {
    return this._name;
  }

  get displayName(): string {
    return this._displayName;
  }

  get alias(): string | undefined {
    return this._alias;
  }

  get icon(): string | undefined {
    return this._icon;
  }

  get isFunction(): boolean {
    return this._isFunction;
  }
}

export interface IPermissionTreeNode {
  data: PermissionGrant;
  checked: boolean;
  label: string;
  children?: IPermissionTreeNode[];
  parent?: IPermissionTreeNode;
}

export function createPermissionGrantTree(
  grantedDataPermissions: DataPermissionSettingDto[],
  items: PermissionDefinitionDto[],
  parentNode?: IPermissionTreeNode
): IPermissionTreeNode[] {
  const result = items.map((definition) => {
    // get dataPermissionSetting of current permissionDefinition
    const dataPermissionSettingFilterResult = grantedDataPermissions.filter(
      (item) => item.permissionCode === definition.name
    );
    const dataPermissionSetting: DataPermissionSettingDto | undefined =
      dataPermissionSettingFilterResult.length
        ? dataPermissionSettingFilterResult[0]
        : undefined;

    const permissionItem = new PermissionItem(definition);

    // build node
    const node = {
      checked: false,
      label:
        permissionItem.displayName +
        (permissionItem.alias ? `(${permissionItem.alias})` : ""),
      data: {
        permission: permissionItem,
      },
      parent: parentNode,
    } as IPermissionTreeNode;

    // configuration data permission
    if (permissionItem.enableDataPermission) {
      node.data.dataLimit = {
        permissionCode: permissionItem.name,
        scope: DataPermissionScopeType.All,
        details: dataPermissionSetting?.details ?? [],
      };

      if (!dataPermissionSetting) {
        // if has not grant data permission , the scope default value is All or Mine
        node.data.dataLimit.scope =
          permissionItem.enableDataPermissionForCreator
            ? DataPermissionScopeType.Mine
            : DataPermissionScopeType.All;
      } else {
        node.data.dataLimit.scope = dataPermissionSetting.scope!;
        // if permissionDefinition disable dataPermission-specifiedLimit, and dataPermission.scope old value is specified
        // then set scope to Mine
        if (
          !permissionItem.enableDataPermissionSpecifiedLimit &&
          dataPermissionSetting.scope === DataPermissionScopeType.Specified
        ) {
          node.data.dataLimit.scope = DataPermissionScopeType.Mine;
        }

        // if permissionDefinition disable dataPermissionLimitByCreator,
        // then set is scope to all
        if (
          !permissionItem.enableDataPermissionForCreator &&
          [
            DataPermissionScopeType.Mine,
            DataPermissionScopeType.MyDepartment,
            DataPermissionScopeType.MyDepartmentAndSubs,
          ].includes(dataPermissionSetting.scope!)
        ) {
          node.data.dataLimit.scope = DataPermissionScopeType.All;
        }
      }
    }

    node.children = createPermissionGrantTree(
      grantedDataPermissions,
      definition.children || [],
      node
    );

    return node;
  });

  return result;
}

export function getGrantedPermissions(
  nodes: IPermissionTreeNode[] | undefined
): string[] {
  let result: string[] = [];

  if (nodes && nodes.length) {
    nodes.forEach((permissionItem) => {
      if (permissionItem.checked) {
        result.push(permissionItem.data.permission.name!);
      }
      result = [...result, ...getGrantedPermissions(permissionItem.children)];
    });
  }

  return result;
}

export function getGrantedDataPermissions(
  nodes: IPermissionTreeNode[] | undefined
): DataPermissionSettingDto[] {
  let result: DataPermissionSettingDto[] = [];
  if (nodes && nodes.length) {
    nodes.forEach((permissionItem) => {
      if (permissionItem.checked) {
        console.log(
          "getGrantedDataPermissions:",
          permissionItem.label,
          permissionItem.data.dataLimit
        );
        if (permissionItem.data.dataLimit) {
          result.push({
            permissionCode: permissionItem.data.dataLimit.permissionCode,
            scope: permissionItem.data.dataLimit.scope,
            details: permissionItem.data.dataLimit.details,
          });
        }
        result = [
          ...result,
          ...getGrantedDataPermissions(permissionItem.children),
        ];
      }
    });
  }
  return result;
}
