





































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import PermissionGrantTree from "@/views/admin/role/permissionGrant/permissionGrantTree.vue";
import api from "@/api/index";
import {
  DataPermissionSettingDto,
  GetRoleForEditOutput,
  PermissionDefinitionDto,
  RoleCreateOrUpdateDto,
} from "@/api/appService";
import {
  createPermissionGrantTree,
  getGrantedDataPermissions,
  getGrantedPermissions,
  IPermissionTreeNode,
} from "@/views/admin/role/permissionGrant/permissionTree";

@Component({
  name: "RoleEditSinglePage",
  components: { PermissionGrantTree },
})
export default class RoleEditSinglePage extends Vue {
  @Ref() readonly dataForm!: ElForm;

  currentTab = "basic";

  permissionItems: IPermissionTreeNode[] = [];

  roleId = 0;

  form: RoleCreateOrUpdateDto = {
    id: 0,
    displayName: "",
    description: "",
    name: "",
    roleScopes: undefined,
    normalizedName: "",
  };

  grantedPermissions: string[] = [];

  async created() {
    console.log("entry role-singleEditPage");
    this.roleId = parseInt((this as any).$route.params.id ?? 0);

    if (this.roleId) {
      api.role
        .getRoleForEdit({ id: this.roleId })
        .then((res: GetRoleForEditOutput) => {
          this.form = { ...this.form, ...res.role };
          if (!this.form.roleScopes) {
            this.form.roleScopes = [];
          }
          if (res.grantedPermissionNames) {
            this.grantedPermissions = res.grantedPermissionNames.map(
              (permission) => permission!
            );
          }
        });
    }
  }

  async save() {
    const formModel = this.$refs.dataForm as any;
    formModel.validate(async (valid: boolean) => {
      if (!valid) {
        return false;
      }
      const input: RoleCreateOrUpdateDto = {
        ...this.form,
      };
      if (this.roleId) {
        await api.role.update({ body: input }).then((res) => {
          (this as any).$message.success("角色更新完成");
          (this as any).$router.back();
        });
      } else {
        await api.role.create({ body: input }).then((res) => {
          (this as any).$message.success("角色创建完成");
          (this as any).$router.back();
        });
      }
    });
  }

  roleRule = {
    name: [
      {
        required: true,
        message: "请输入角色编号",
        trigger: "blur",
      },
      {
        type: "string",
        pattern: "^([a-zA-Z]{1}[a-zA-Z0-9]{0,100})$",
        message: "角色编号仅能为字母与数字的组合，且只能以字母开头",
        trigger: "blur",
      },
      {
        max: 100,
        message: "最多100个字符",
        trigger: "blur",
      },
    ],
    displayName: [
      {
        required: true,
        message: "请输入角色名称",
        trigger: "blur",
      },
      {
        max: 100,
        message: "最多100个字符",
        trigger: "blur",
      },
    ],
    description: [
      { required: false },
      {
        max: 200,
        message: "最多200个字符",
      },
    ],
  };
}
