

















import api from "@/api/index";
import { DisplayItem } from "@/api/appService";
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "DataPermissionDataSourceAlbum",
})
export default class DataPermissionDataSourceAlbum extends Vue {
  displayItems: DisplayItem[] = [];

  @Model("change", { required: false, default: [] })
  grantValues!: string[];

  values: string[] = [];

  created() {
    this.values = this.grantValues;
    api.dataPermissionScopeProvider
      .getItems({ providerName: "Album" })
      .then((values) => {
        this.displayItems = values;
      });
  }

  @Watch("values", { deep: true })
  onValuesChange(newValue: string[]) {
    this.$emit("change", newValue);
  }
}
