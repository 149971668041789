



























import { Component, Vue } from "vue-property-decorator";
import api from "@/api/index";
import {
  DataPermissionSettingDto,
  GetRoleForEditOutput,
  PermissionDefinitionDto,
  RoleCreateOrUpdateDto,
  RoleGrantPermissionInput,
} from "@/api/appService";
import {
  createPermissionGrantTree,
  getGrantedDataPermissions,
  getGrantedPermissions,
  IPermissionTreeNode,
} from "@/views/admin/role/permissionGrant/permissionTree";
import PermissionGrantTree from "@/views/admin/role/permissionGrant/permissionGrantTree.vue";

@Component({
  components: {
    PermissionGrantTree,
  },
})
export default class RoeGrantPermissions extends Vue {
  roleId = 0;

  form: RoleCreateOrUpdateDto = {};
  grantedPermissions: string[] = [];
  permissionItems: IPermissionTreeNode[] = [];
  loading = true;

  submitState = ""; // empty | submitting

  async created() {
    this.roleId = parseInt((this as any).$route.params.id);

    await this.initPermissionGrantTree();

    api.role
      .getRoleForEdit({ id: this.roleId })
      .then((res: GetRoleForEditOutput) => {
        this.form = { ...this.form, ...res.role };
        if (res.grantedPermissionNames) {
          this.grantedPermissions = res.grantedPermissionNames;
        }
        this.loading = false;
      });
  }

  async initPermissionGrantTree() {
    let permissionDefinitions: PermissionDefinitionDto[] = [];
    await api.permission.getAllPermissions().then((result) => {
      permissionDefinitions = result;
    });
    let grantedDataPermissions: DataPermissionSettingDto[] = [];
    await api.role
      .getGrantedDataPermissionSettings({ roleId: this.roleId })
      .then((res) => {
        grantedDataPermissions = res;
      });

    this.permissionItems = createPermissionGrantTree(
      grantedDataPermissions,
      permissionDefinitions,
      undefined
    );
  }

  save() {
    const input: RoleGrantPermissionInput = {
      roleId: this.roleId,
      grantedPermissions: getGrantedPermissions(this.permissionItems),
      dataPermissionSettings: getGrantedDataPermissions(this.permissionItems),
    };

    this.submitState = "submitting";
    api.role
      .grantPermission({ body: input })
      .then((res) => {
        (this as any).$message.success("角色权限分配完成");
        (this as any).$router.back();
        this.submitState = "";
      })
      .catch(() => {
        this.submitState = "";
      });
  }

  get pageTitle() {
    if (!this.form.displayName) {
      return "分配权限";
    } else {
      return "分配权限 - " + this.form.displayName;
    }
  }
}
