




























import api from "@/api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DataPermissionDataSourceCmsCategories from "@/views/admin/role/permissionGrant/providers/cmsCategories.vue";
import DataPermissionDataSourceAlbum from "@/views/admin/role/permissionGrant/providers/album.vue";
import { IPermissionTreeNode } from "@/views/admin/role/permissionGrant/permissionTree";
import DataPermissionGrant from "@/views/admin/role/permissionGrant/components/dataPermissionGrant.vue";
import FunctionGrant from "@/views/admin/role/permissionGrant/components/functionGrant.vue";

@Component({
  name: "PermissionGrantTree",
  components: {
    FunctionGrant,
    DataPermissionGrant,
    DataPermissionDataSourceCmsCategories,
    DataPermissionDataSourceAlbum,
  },
})
export default class PermissionGrantTree extends Vue {
  @Prop({ required: true })
  nodes!: IPermissionTreeNode[];

  @Prop({ required: false })
  parentNode?: IPermissionTreeNode;

  @Prop({ required: false })
  grantedPermissions?: string[];

  expand = true;

  @Watch("grantedPermissions")
  onGrantedPermissionsChange(newValue: string[]) {
    if (!newValue || !newValue.length) {
      this.cancelAllGrant();
    } else {
      this.grantPermissions(this.nodes, newValue);
    }
  }

  created(): void {
    // this.activeCollapses = this.nodes.map((item) => item.id);
  }

  get menus() {
    return this.nodes.filter((s) => !s.data.permission.isFunction);
  }

  onPermissionGrantChange(
    newValue: boolean,
    permissionItem: IPermissionTreeNode
  ) {
    if (newValue) {
      this.grantAllParents(permissionItem);
      this.grantAllChildren(permissionItem);
    } else {
      this.cancelGrantAllChildren(permissionItem);
    }
  }

  grantAllParents(permissionItem?: IPermissionTreeNode) {
    if (permissionItem && permissionItem.parent) {
      permissionItem.parent.checked = true;
    }
  }

  grantAllChildren(permissionItem: IPermissionTreeNode) {
    if (permissionItem.children && permissionItem.children.length) {
      permissionItem.children.forEach((item) => {
        console.log("grantAllChildren grant:", item.label);
        item.checked = true;
        this.grantAllChildren(item);
      });
    }
  }

  cancelGrantAllChildren(permissionItem: IPermissionTreeNode) {
    if (permissionItem.children) {
      permissionItem.children.forEach((item) => {
        item.checked = false;
        this.cancelGrantAllChildren(item);
      });
    }
  }

  cancelAllGrant() {
    this.nodes.forEach((node) => {
      node.checked = false;
      this.cancelGrantAllChildren(node);
    });
  }

  grantPermissions(
    nodes: IPermissionTreeNode[] | undefined,
    grantedPermissions: string[]
  ) {
    if (nodes && nodes.length) {
      nodes.forEach((item) => {
        item.checked = grantedPermissions.some(
          (permission) => permission === item.data.permission.name
        );
        this.grantPermissions(item.children, grantedPermissions);
      });
    }
  }
}
