






































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  IPermissionTreeNode,
  PermissionItem,
} from "@/views/admin/role/permissionGrant/permissionTree";
import DataPermissionDataSourceAlbum from "@/views/admin/role/permissionGrant/providers/album.vue";
import DataPermissionDataSourceCmsCategories from "@/views/admin/role/permissionGrant/providers/cmsCategories.vue";

@Component({
  name: "DataPermissionGrant",
  components: {
    DataPermissionDataSourceAlbum,
    DataPermissionDataSourceCmsCategories,
  },
})
export default class DataPermissionGrant extends Vue {
  @Prop({ required: true })
  node!: IPermissionTreeNode;

  get permissionDefinition(): PermissionItem {
    return this.node.data.permission;
  }

  get dataLimitSetting() {
    return this.node.data.dataLimit;
  }

  get dataPermissionProvider(): string {
    return this.permissionDefinition.enableDataPermission &&
      this.permissionDefinition.enableDataPermissionSpecifiedLimit
      ? this.permissionDefinition.dataPermissionSpecifiedDataSourceProvider!
      : "";
  }
}
